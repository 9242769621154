import { Auth } from 'aws-amplify';
import { AuthenticationInfo } from './generated/graphql';
import { getOpenIdToken } from './queries';

function getIdentityIdFromToken(token: string) {
  const parsed = JSON.parse(atob(token.split('.')[1]));
  const { sub } = parsed;

  return sub;
}

function getExpiresAt(token: string) {
  const parsed = JSON.parse(atob(token.split('.')[1]));
  const { exp } = parsed;

  return exp * 1000; // Should be millis
}

const createTokenInfoFetcher =
  (authenticationInfo: AuthenticationInfo) => async () => {
    const { authenticate } = await getOpenIdToken({
      authenticationInfo,
    });

    if (!authenticate) {
      return null;
    }

    const { token, identityId } = authenticate;

    return {
      token,
      identity_id: identityId,
      expires_at: getExpiresAt(token),
    };
  };

export async function signIn(
  authenticationInfo: AuthenticationInfo,
): Promise<boolean> {
  const tokenInfoFetcher = createTokenInfoFetcher(authenticationInfo);
  Auth.configure({
    refreshHandlers: {
      developer: tokenInfoFetcher,
    },
  });

  let loggedIn = false;
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    if (currentUser.woningdossiertoken === authenticationInfo.token) {
      loggedIn = true;
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
  if (!loggedIn) {
    const tokenInfo = await tokenInfoFetcher();
    if (!tokenInfo) {
      return false;
    }
    await Auth.federatedSignIn('developer', tokenInfo, {
      name: 'FederatedUser',
      // @ts-ignore
      woningdossiertoken: authenticationInfo.token,
    });
  }
  return true;
}

export async function adminSignin(token: string) {
  await Auth.federatedSignIn(
    'developer',
    {
      token,
      expires_at: getExpiresAt(token),
      identity_id: getIdentityIdFromToken(token),
    },
    {
      name: 'FederatedUser',
    },
  );
}

export async function isSignedIn() {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (e) {
    return false;
  }
}
