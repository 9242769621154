import { Amplify } from 'aws-amplify';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';

import { App, AppProps } from './App';
import awsconfig from './aws-exports';
import './polyfills';
import { setVersion } from './set-version';

export { i18n } from './i18n';

export function startApp(appProps: AppProps) {
  Amplify.configure(awsconfig);
  setVersion();

  const container = document.getElementById('root');
  if (!container) {
    throw new Error('could not find root container');
  }
  const root = ReactDOMClient.createRoot(container);
  root.render(
    <React.StrictMode>
      <App {...appProps} />
    </React.StrictMode>,
  );
}
