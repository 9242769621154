import Typography from '@material-ui/core/Typography';
import { DataSource } from '@nxworkspace/gql-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAsync } from '../async-hook';
import { retrieveDataSource } from '../queries';
import { createOverviewRoute } from '../routes';
import { ShowData } from '../ShowData';

export function DataLoading() {
  const { t } = useTranslation();
  return <Typography variant="h4">{t('genericLoadingMessage')}</Typography>;
}

export function DataSourceRetrieve({ dataSource }: { dataSource: DataSource }) {
  const queryState = useAsync(
    useCallback(() => retrieveDataSource(dataSource), [dataSource]),
  );
  const navigate = useNavigate();
  if (queryState.status === 'pending') {
    return <DataLoading />;
  }
  return (
    <ShowData
      data={queryState.value}
      onSendClick={() => {
        navigate(createOverviewRoute());
      }}
      onBackClick={() => {
        navigate(createOverviewRoute());
      }}
    />
  );
}
