import { useUrlParameters } from '@nxworkspace/frontend-utils';
import { useEffect } from 'react';
import { create } from 'zustand';
import { AuthenticationInfo } from '../generated/graphql';
import { useHuurcheckUrlParams } from '../hooks/huurcheck-url-params';

export type UserInfo = {
  userId: string;
  personaliaId: string;
};

export type CorrelationInfo = {
  userInfo?: UserInfo;
  authenticationInfo: AuthenticationInfo;
};

export type CorrelationInfoStore = {
  info: CorrelationInfo | null;
  setCorrelationInfo: (info: CorrelationInfo) => void;
};

const useAuthenticationInfoStore = create<CorrelationInfoStore>((set) => ({
  info: null,
  setCorrelationInfo: (info) =>
    set(() => ({
      info,
    })),
}));

export function findAuthenticationInfo(): AuthenticationInfo | null {
  return useAuthenticationInfoStore.getState().info?.authenticationInfo ?? null;
}

export function useStoreAuthenticationInfo() {
  const { setCorrelationInfo } = useAuthenticationInfoStore();

  const urlParameters = useUrlParameters();
  const { userId, personaliaId } = useHuurcheckUrlParams();
  useEffect(() => {
    const urlParamToken = urlParameters.token?.replace(/ /g, '+');
    if (!urlParamToken) {
      return;
    }

    const authenticationInfo: AuthenticationInfo = {
      token: urlParamToken,
      referrer: document.referrer,
    };
    const userInfo =
      (userId && personaliaId && { userId, personaliaId }) || undefined;
    setCorrelationInfo({
      authenticationInfo,
      userInfo,
    });
  }, [urlParameters.token, setCorrelationInfo, userId, personaliaId]);
}

export function useGetAuthenticationInfo(): AuthenticationInfo {
  const authenticationInfo = useAuthenticationInfoStore(
    (state) => state.info?.authenticationInfo,
  );
  if (!authenticationInfo) {
    throw new Error('Could not determine authentication info');
  }
  return authenticationInfo;
}

export function useUserInfo(): UserInfo | undefined {
  return useAuthenticationInfoStore((state) => state.info?.userInfo);
}
