import { create } from 'zustand';

export type LoginType = 'app' | 'password';

export type LoginTypeState = {
  loginType: LoginType;
  setLoginType: (loginType: LoginType) => void;
};

export const useLoginType = create<LoginTypeState>((set) => ({
  loginType: 'password',
  setLoginType: (loginType) =>
    set(() => ({
      loginType,
    })),
}));
