import axios from 'axios';

function formatErrorMessage(message: string) {
  if (message.includes('correlationId=')) {
    return message.replace(/.*correlationId=/, '');
  }
  return message;
}

export interface GraphqlError {
  message: string;
  originalError: unknown;
}

export interface GraphqlErrorResponse {
  errors: GraphqlError[];
}

function isGraphqlError(error: unknown): error is GraphqlErrorResponse {
  if (typeof error !== 'object') {
    return false;
  }
  const graphqlErrorResponse = error as GraphqlErrorResponse;
  return (
    'errors' in graphqlErrorResponse &&
    graphqlErrorResponse.errors.every(({ message }) => !!message)
  );
}

type AwsError = { errorType: string; message: string };

type AwsErrorResponse = { errors: AwsError[] };

function isAwsErrorResponse(response: unknown): response is AwsErrorResponse {
  if (typeof response !== 'object') {
    return false;
  }
  const awsErrorResponse = response as AwsErrorResponse;
  return (
    'errors' in awsErrorResponse &&
    awsErrorResponse.errors.every(
      (error) =>
        'errorType' in (error as AwsError) && 'message' in (error as AwsError),
    )
  );
}

export function formatUnknownError(error: unknown) {
  if (isGraphqlError(error)) {
    const result = error.errors
      .map(({ message, originalError }) => {
        if (axios.isAxiosError(originalError)) {
          const responseData = originalError.response?.data;
          if (responseData) {
            const errorText = isAwsErrorResponse(responseData)
              ? responseData.errors
                  .map(({ errorType, message: message1 }) => {
                    return `${errorType} - ${message1}`;
                  })
                  .join('; ')
              : JSON.stringify(responseData);
            return `Status ${
              originalError.response?.status ?? 'none'
            }, ${errorText}`;
          }
        }
        return formatErrorMessage(message);
      })
      .join('. ');
    if (
      result.includes('Signature expired') ||
      result.includes('Signature not yet current')
    ) {
      return `Set the time on your device correctly. Otherwise you run into the following error: ${result}`;
    }
    return result;
  }

  if (error instanceof Error) {
    return formatErrorMessage(error.message);
  }
  return JSON.stringify(error);
}
