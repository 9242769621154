import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/600.css';
import '@nxworkspace/frontend';
import { i18n, startApp } from '@nxworkspace/frontend';
import en from './i18n/en.json';
import nl from './i18n/nl.json';
import { muiTheme } from './mui-themes';
import { TryChameleonIdentify } from './trychameleon';

i18n
  .addResources('nl', 'translation', nl)
  .addResources('en', 'translation', en)
  .init({
    interpolation: {
      defaultVariables: {
        brand: 'DigitaalWoningDossier',
      },
    },
  });

startApp({
  plugin: <TryChameleonIdentify />,
  muiTheme,
});
