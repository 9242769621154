import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLoginType } from '../common/login-type-store';

export function LoginTypeToggle() {
  const { t } = useTranslation();
  const { loginType, setLoginType } = useLoginType();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = (event.target as HTMLInputElement).value;
    if (value !== 'password' && value !== 'app') {
      throw new Error(`Invalid login type ${value}`);
    }
    setLoginType(value);
  }

  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">
        {t('Hoe wilt u inloggen?')}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={loginType}
        onChange={handleChange}
      >
        <FormControlLabel
          value="password"
          control={<Radio />}
          label={t('Met een sms-controle')}
        />
        <FormControlLabel
          value="app"
          control={<Radio />}
          label={t('Met de DigiD app')}
        />
      </RadioGroup>
    </FormControl>
  );
}
