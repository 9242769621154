import { useEffect } from 'react';
import { adminSignin } from './signin-service';

export function useAdminLogin(onLogin: (() => void) | undefined = undefined) {
  useEffect(() => {
    window.adminLogin = (token) => {
      (async () => {
        await adminSignin(token);
        onLogin?.();
      })();
    };
  }, [onLogin]);
}
