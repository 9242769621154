import { useLoaderData } from 'react-router-dom';
import { AppProps } from '../App';
import { findAuthenticationInfo } from '../common/correlation-info-store';
import { DataSet } from '../core/DataSet';
import { getDataSet } from '../queries';
import { isSignedIn, signIn } from '../signin-service';
import { DataSetOverview } from './DataSetOverview';

type LoaderResult = DataSet;

export const datasetOverviewLoader =
  (appProps: AppProps) => async (): Promise<LoaderResult> => {
    const authenticationInfo = findAuthenticationInfo();

    if (authenticationInfo) {
      await signIn(authenticationInfo);
    } else if (!(await isSignedIn())) {
      throw new Error('Single signon failed');
    }

    return new DataSet(await getDataSet(), appProps.supportedDataSources);
  };

export default function DataSetOverviewPage() {
  const dataSet = useLoaderData() as LoaderResult;
  return <DataSetOverview dataSet={dataSet} />;
}
