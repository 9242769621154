import React from 'react';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { stringToDataSource } from '../datasource-info';
import { hasSession } from '../queries';
import { useRequiredParam } from '../router-utils';
import { createOverviewRoute } from '../routes';
import { DataSourceNotFound } from './DataSourceNotFound';
import { DataSourceRetrieve } from './DataSourceRetrieve';

export function datasourceRetrievalLoader({
  params: { dataSource: datasourceParam },
}: LoaderFunctionArgs) {
  if (datasourceParam) {
    const dataSource = stringToDataSource[datasourceParam];
    if (!dataSource) {
      return null;
    }
  }
  if (!hasSession()) {
    return redirect(createOverviewRoute());
  }
  return null;
}

export function DataSourceRetrievePage() {
  const dataSourceParam = useRequiredParam('dataSource');
  const dataSource = stringToDataSource[dataSourceParam];
  if (!dataSource) {
    return <DataSourceNotFound dataSource={dataSourceParam} />;
  }
  return <DataSourceRetrieve dataSource={dataSource} />;
}
