import { Theme } from '@material-ui/core/styles';
import React, { ReactNode, Suspense } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { ErrorElement } from '../ErrorElement';
import './App.css';
import { CardWrapper } from './AppWrapper';
import { DataSetKeys } from './core/DataSet';
import { SetupGlobals } from './DataSetLogin/SetupGlobals';
import { datasetOverviewLoader } from './DataSetOverview/DataSetOverviewPage';
import {
  datasourceRetrievalLoader,
  DataSourceRetrievePage,
} from './DataSourceRetrievePage/DataSourceRetrievePage';
import { Introduction } from './Introduction';
import { NotFoundPage } from './NotFoundPage';
import { Page } from './Page';
import { resultPageLoader } from './ResultPage/ResultPage';

const DataSetOverviewPage = React.lazy(
  () => import('./DataSetOverview/DataSetOverviewPage'),
);

const DataSetLoginPage = React.lazy(
  () => import('./DataSetLogin/DataSetLoginPage'),
);
const ResultPage = React.lazy(() => import('./ResultPage/ResultPage'));
const SendDataPage = React.lazy(() => import('./SendData/SendData'));
const PrintPage = React.lazy(() => import('./PrintPage/PrintPage'));

export interface MuiTheme {
  print: Partial<Theme>;
  display: Partial<Theme>;
}

export interface AppProps {
  plugin?: ReactNode;
  muiTheme: MuiTheme;
  supportedDataSources?: DataSetKeys[];
  resultPageNextPage?: string;
}

export function createRoutes(appProps: AppProps) {
  const { plugin, muiTheme, resultPageNextPage } = appProps;
  return [
    {
      element: (
        <Page muiTheme={muiTheme.display}>
          {plugin}
          <SetupGlobals />
          <Outlet />
        </Page>
      ),
      errorElement: (
        <Page muiTheme={muiTheme.display}>
          <ErrorElement />
        </Page>
      ),
      children: [
        {
          element: (
            <CardWrapper>
              <Outlet />
            </CardWrapper>
          ),
          children: [
            {
              path: '/overview',
              element: <DataSetOverviewPage />,
              loader: datasetOverviewLoader(appProps),
            },
            {
              path: '/login/:dataSource',
              element: <DataSetLoginPage />,
            },
            {
              path: '*',
              element: <NotFoundPage />,
            },
            {
              path: '/',
              element: <Introduction />,
            },
          ],
        },
        {
          element: (
            <CardWrapper maxWidth="lg">
              <Outlet />
            </CardWrapper>
          ),
          children: [
            {
              path: '/retrieve/:dataSource',
              loader: datasourceRetrievalLoader,
              element: <DataSourceRetrievePage />,
            },
            {
              path: '/result/',
              loader: resultPageLoader(appProps),
              element: <ResultPage nextPage={resultPageNextPage} />,
            },
            {
              path: '/send-data/',
              element: <SendDataPage />,
            },
          ],
        },
      ],
    },

    {
      element: (
        <Page muiTheme={muiTheme.print} printMode>
          {plugin}
          <SetupGlobals />
          <Outlet />
        </Page>
      ),
      children: [
        {
          path: '/print',
          element: <PrintPage />,
        },
      ],
    },
  ];
}

function createRouter(appProps: AppProps) {
  return createBrowserRouter(createRoutes(appProps));
}

export const App = (appProps: AppProps) => {
  return (
    <Suspense>
      <RouterProvider router={createRouter(appProps)} />
    </Suspense>
  );
};
