import { StateValue, SystemStatusQuery } from './generated/graphql';

export function getAlarmSystems(systemStatusState: {
  status: 'resolved';
  value: SystemStatusQuery['systemStatus'];
}) {
  return Object.entries(systemStatusState.value ?? {})
    .filter(
      ([, status]) =>
        typeof status !== 'string' && status?.state === StateValue.Alarm,
    )
    .map(([datasource]) => datasource);
}
