import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './i18n/en.json';
import nl from './i18n/nl.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      nl: {
        translation: {
          ...nl,
        },
      },
      en: {
        translation: {
          ...en,
        },
      },
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: false,
    fallbackLng: ['nl', 'en'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export { i18n };
