import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Variant } from '@material-ui/core/styles/createTypography';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import decamelize from 'decamelize';
import React, { Fragment, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { graphqlFieldToDisplayName } from './datasource-info';
import { GraphqlError, GraphqlResult } from './queries';

function formatScalar(value: any) {
  if (noValue(value)) {
    return 'Niet gevonden';
  }
  if (typeof value === 'boolean') {
    return value ? 'Ja' : 'Nee';
  }
  if (typeof value === 'string') {
    if (/^\d{4}-\d\d-\d\d\+\d\d:\d\d$/.test(value)) {
      const dateString = value.replace(/\+.*$/, '');
      const [year, month, day] = dateString.split('-');
      const date = new Date(+year, +month - 1, +day);
      return date.toLocaleDateString('nl-NL', {
        year: 'numeric',
        day: 'numeric',
        month: 'long',
      });
    }
    if (/^\d+\.\d{2}$/.test(value)) {
      return Number(value).toLocaleString('nl-NL', {
        minimumFractionDigits: 2,
      });
    }
  }
  if (typeof value === 'number') {
    return value.toLocaleString('nl-NL');
  }
  return value;
}

export function formatKey(value: string) {
  return decamelize(value, ' ')
    .replace(/((^| )\w)/g, (_, p1) => p1.toUpperCase())
    .replace(' Connection', '');
}

const useDescriptionListStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      pageBreakInside: 'avoid',
      margin: '0',
      marginBottom: theme.spacing(2),
    },
    term: {
      paddingLeft: theme.spacing(1),
    },
    description: {
      margin: 0,
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    emphasis: {
      backgroundColor: theme.palette.grey['200'],
    },
    '@supports (display: grid)': {
      root: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('sm')]: {
          gridTemplateColumns: '27ch auto',
        },
        '@media print': {
          gridTemplateColumns: '27ch auto',
        },
        gridRowGap: theme.spacing(1),
      },
      description: {
        marginBottom: 0,
      },
    },
  };
});

function DescriptionList({ data }: { data: DescriptionListRow[] }) {
  const classes = useDescriptionListStyles();
  return (
    <dl className={classes.root}>
      {data.map(({ key, value, emphasis }) => (
        <Fragment key={key}>
          <dt
            className={classnames(classes.term, {
              [classes.emphasis]: emphasis,
            })}
          >
            <Typography
              variant="body1"
              color={emphasis ? undefined : 'textSecondary'}
            >
              {formatKey(key)}
            </Typography>
          </dt>
          <dd
            className={classnames(classes.description, {
              [classes.emphasis]: emphasis,
            })}
          >
            <Typography variant="body1">
              {key === 'jaar' ? value : formatScalar(value)}
            </Typography>
          </dd>
        </Fragment>
      ))}
    </dl>
  );
}

function isEmptyArray(value: any) {
  return Array.isArray(value) && value.length === 0;
}

function noValue(value: any) {
  return value === null || isEmptyArray(value);
}

function normalizeConnection(
  name: string,
  value: {
    edges?: { node: any }[];
    [key: string]: any;
  },
) {
  if (value.edges) {
    const { edges, ...other } = value;
    const keys = Object.keys(value);
    const nodes = edges.map(({ node }) => node);
    if (keys.length === 1) {
      return nodes;
    }

    return {
      ...other,
      [name]: nodes,
    };
  }
  return value;
}

export function splitObject(object: object): {
  descriptionListData: DescriptionListRow[];
  listData: Record<string, any[]>;
  nestedData: object;
} {
  const descriptionListData: DescriptionListRow[] = [];
  const listEntries: [string, any[]][] = [];
  const otherEntries: [string, any][] = [];
  Object.entries(object).forEach((entry) => {
    const [key, value] = entry;
    if (key === '__typename') {
      return;
    }
    if (typeof value === 'object' && !noValue(value)) {
      const normalValue = normalizeConnection(key, value);
      if (Array.isArray(normalValue)) {
        listEntries.push([key, normalValue]);
      } else {
        otherEntries.push([key, normalValue]);
      }
    } else {
      descriptionListData.push({
        key,
        value,
        emphasis: false,
      });
    }
  });

  return {
    descriptionListData,
    listData: Object.fromEntries(listEntries),
    nestedData: Object.fromEntries(otherEntries),
  };
}

const levels: Variant[] = ['h2', 'h3', 'h4', 'h5', 'subtitle1', 'subtitle2'];
const getHeader = (level: number): Variant => {
  return levels[level] ?? levels[levels.length - 1];
};

function getListHeader(name: string, i: number, item: any) {
  if (item.jaar) {
    return `${name} ${item.jaar}`;
  }
  if (item.periodeStart) {
    return `${name} ${formatScalar(item.periodeStart)}`;
  }
  if (item.ingangsdatumGeldigheid && name !== 'ouders') {
    return `${name} ${formatScalar(item.ingangsdatumGeldigheid)}`;
  }
  return `${name} ${i + 1}`;
}

function mapListData(
  headers: (string | false)[],
  listProps: Record<string, any[]>,
): DescriptionListSection[] {
  const followupHeaders = headers.map((): DescriptionListHeader => false);
  return Object.entries(listProps).flatMap(([name, items], propIndex) => {
    return items.flatMap((item, itemIndex) => {
      const newHeaders = [
        ...(propIndex === 0 && itemIndex === 0 ? headers : followupHeaders),
        getListHeader(name, itemIndex, item),
      ];
      return mapData(newHeaders, item);
    });
  });
}

function mapEmptyWerknlData([key, value]: [string, unknown]): [string, any] {
  if (key === 'werkNlData') {
    if ((value as { persoonsgegevens: unknown }).persoonsgegevens === null) {
      return [
        key,
        {
          status: 'Situatie technisch nog niet ondersteund',
        },
      ];
    }
  }
  return [key, value];
}

export interface ShowGraphqlResultProps extends GraphqlResult {
  data: GraphqlResult;
}

export interface TechnicalErrorsProps {
  errors: GraphqlError[];
}

export const TechnicalErrors = ({ errors }: TechnicalErrorsProps) => (
  <ul>
    {errors
      .filter(
        (error): error is { path: (string | number)[] } => !!error.path?.length,
      )
      .map(({ path }) => (
        <Typography key={path.join('.')} component="li">
          {path
            .map((key) => (typeof key === 'number' ? key : formatKey(key)))
            .join(' › ')}
        </Typography>
      ))}
  </ul>
);

const useHowDatasourceSection = makeStyles(() => {
  return {
    alwaysBreakPageBefore: {
      pageBreakBefore: 'always',
    },
    noPageBreakInside: {
      pageBreakInside: 'avoid',
    },
    columnContainer: {
      '@media print': {
        columnCount: 2,
      },
    },
  };
});

interface ShowDataSourceSectionProps extends DataSourceSection {
  showPageBreak: boolean;
}

export const ShowDataSourceSection = ({
  dataSourceField,
  descriptionLists,
  showPageBreak,
}: ShowDataSourceSectionProps) => {
  const classes = useHowDatasourceSection();
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      className={showPageBreak ? classes.alwaysBreakPageBefore : ''}
    >
      <Grid item>
        <Typography variant={getHeader(0)}>
          {graphqlFieldToDisplayName(dataSourceField)}
        </Typography>
      </Grid>
      <Grid item className={classes.columnContainer}>
        {descriptionLists.map(({ headers, descriptions }, dlIndex) => (
          <div className={classes.noPageBreakInside} key={dlIndex}>
            {headers.map((header, headerIndex) => (
              <Fragment key={headerIndex}>
                {header && (
                  <Typography variant={getHeader(headerIndex)}>
                    {header}
                  </Typography>
                )}
              </Fragment>
            ))}
            <DescriptionList data={descriptions} />
          </div>
        ))}
      </Grid>
    </Grid>
  );
};

type DescriptionListHeader = string | false;

export interface DescriptionListRow {
  key: string;
  value: string;
  emphasis: boolean;
}

export interface DescriptionListSection {
  headers: DescriptionListHeader[];
  descriptions: DescriptionListRow[];
}

export interface DataSourceSection {
  dataSourceField: string;
  descriptionLists: DescriptionListSection[];
}

export function mapNestedData(
  headers: DescriptionListHeader[],
  data: any,
): DescriptionListSection[] {
  const followupHeaders = headers.map((): DescriptionListHeader => false);
  return Object.entries(data).flatMap(([key, value], i) => {
    const newHeaders = [...(i === 0 ? headers : followupHeaders), key];
    return mapData(newHeaders, value);
  });
}

export function mapData(
  headers: DescriptionListHeader[],
  data: any,
): DescriptionListSection[] {
  const { descriptionListData, listData, nestedData } = splitObject(data);
  const hasDescriptionList = !!descriptionListData.length;
  const hasListData = !!Object.keys(listData).length;
  const hasNestedData = !!Object.keys(nestedData).length;
  const followupHeaders = headers.map((): DescriptionListHeader => false);
  return [
    ...(hasDescriptionList
      ? [{ headers: headers, descriptions: descriptionListData }]
      : []),
    ...(hasNestedData
      ? mapNestedData(
          hasDescriptionList ? followupHeaders : headers,
          nestedData,
        )
      : []),
    ...(hasListData
      ? mapListData(
          hasDescriptionList || hasNestedData ? followupHeaders : headers,
          listData,
        )
      : []),
  ];
}

type HeaderSearch = (string | RegExp)[];

function headerMatches(
  input: (string | boolean)[],
  search: HeaderSearch,
): boolean {
  const inputOffset = input.length - search.length;
  if (inputOffset < 0) {
    return false;
  }
  return search.every((value, i) => {
    const inputElement = input[i + inputOffset];
    if (value instanceof RegExp) {
      if (typeof inputElement === 'boolean') {
        return false;
      }
      return value.test(inputElement);
    }
    return inputElement === value;
  });
}

let mergedHeaders: (boolean | string)[] = [];

function mergeHeader(headers: (boolean | string)[]) {
  if (headers.length < mergedHeaders.length) {
    mergedHeaders = mergedHeaders.slice(0, headers.length);
  }
  headers.forEach((header, i) => {
    if (header) {
      if (i === mergedHeaders.length) {
        mergedHeaders.push(header);
      } else {
        mergedHeaders[i] = header;
      }
    }
  });
}

type DlSectionProcessor = (
  dlSection: DescriptionListSection,
  matchCount: number,
) => DescriptionListSection;

interface DlSectionProcessorConfig {
  search: HeaderSearch;
  processor: DlSectionProcessor;
}

const emphasizeAll: DlSectionProcessor = (dlSection) => ({
  ...dlSection,
  descriptions: dlSection.descriptions.map((description) => ({
    ...description,
    emphasis: true,
  })),
});

const dlProcessorConfigs: DlSectionProcessorConfig[] = [
  {
    search: [],
    processor({ headers, descriptions }) {
      return {
        headers: headers.map((header) => header && formatKey(header)),
        descriptions,
      };
    },
  },
  {
    search: ['persoonsgegevens', 'identiteitsgegevens'],
    processor: emphasizeAll,
  },
  {
    search: ['persoonsgegevens', 'identiteitsgegevens', 'document'],
    processor: emphasizeAll,
  },
  {
    search: ['persoonsgegevens', 'identiteitsgegevens', 'inschrijving'],
    processor: emphasizeAll,
  },
  { search: ['mijngegevens'], processor: emphasizeAll },
  { search: ['indicatieWwUitkering'], processor: emphasizeAll },
  { search: ['arbeidsverleden'], processor: emphasizeAll },
  {
    search: ['persoonsgegevens', /^adresgegevens.*/],
    processor({ headers, descriptions }, matchCount) {
      const lastIndex = headers.length - 1;
      return {
        headers:
          matchCount === 0
            ? [
                ...headers.slice(0, lastIndex),
                'Huidig Woonadres (BRP)',
                headers[lastIndex],
              ]
            : headers,
        descriptions: descriptions.map((dlRow) => {
          return {
            ...dlRow,
            emphasis:
              matchCount === 0 ||
              [
                'functieAdres',
                'datumAanvang',
                'datumInschrijvingGemeente',
                'ingangsdatumGeldigheid',
              ].includes(dlRow.key),
          };
        }),
      };
    },
  },
  {
    search: [/^woz/],
    processor({ headers, descriptions }) {
      return {
        headers: headers.map((header) =>
          header === 'Woz'
            ? 'Wozwaarde van de huidige huur- of koopwoning + van alle overige objecten in eigendom'
            : header,
        ),
        descriptions,
      };
    },
  },
  { search: [/^werkgevergegevens/], processor: emphasizeAll },
  {
    search: [/^(werkgevergegevens|loongegevensPerMaand)/],
    processor({ headers, descriptions }) {
      return {
        headers: [
          ...headers.slice(0, headers.length - 2),
          headers[headers.length - 1],
        ],
        descriptions,
      };
    },
  },

  {
    search: [/^loongegevensPerMaand/],
    processor({ headers, descriptions }, matchCount) {
      const lastIndex = headers.length - 1;
      return {
        headers: [
          ...headers.slice(0, lastIndex),
          matchCount === 0 ? 'Loongegevens afgelopen 9 maanden' : false,
          headers[lastIndex],
        ],
        descriptions,
      };
    },
  },
];

let matchCounts: number[] = [];

export function processDescriptionLists(
  dataSourceField: string,
  dlSection: DescriptionListSection,
): DescriptionListSection {
  const { headers } = dlSection;
  mergeHeader(headers);
  return dlProcessorConfigs.reduce((acc, { search, processor }, i) => {
    if (headerMatches(mergedHeaders, search)) {
      const processed = processor(acc, matchCounts[i]);
      matchCounts[i]++;
      return processed;
    }
    return acc;
  }, dlSection);
}

export function mapPageData(data: any): DataSourceSection[] {
  matchCounts = Array(dlProcessorConfigs.length).fill(0);
  return Object.entries(data)
    .map(mapEmptyWerknlData)
    .map(([key, value]) => {
      return {
        dataSourceField: key,
        descriptionLists: mapData([false], value),
      };
    })
    .map(({ dataSourceField, descriptionLists }) => ({
      dataSourceField,
      descriptionLists: descriptionLists.map((dlList) =>
        processDescriptionLists(dataSourceField, dlList),
      ),
    }));
}

export const ShowGraphqlResult = ({
  data: { data, errors },
}: ShowGraphqlResultProps) => {
  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const { t } = useTranslation();
  const mappedData = useMemo(() => mapPageData(data), [data]);
  return (
    <>
      <Typography variant="h1" paragraph align="center">
        {t('showData.subTitle')}
      </Typography>
      {mappedData.map((datasourceData, i) => (
        <ShowDataSourceSection
          key={datasourceData.dataSourceField}
          {...datasourceData}
          showPageBreak={!!i}
        />
      ))}
    </>
  );
};
