import {
  Box,
  Button,
  Grid,
  Step,
  StepButton,
  StepContent,
  Stepper,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DataSet } from '../core/DataSet';
import { appNameToDisplayName } from '../datasource-info';
import { createLoginRoute } from '../routes';

export function DataSetStepper({ dataSet }: { dataSet: DataSet }) {
  const [activeStep, setActiveStep] = React.useState(dataSet.activeStep);

  const { t } = useTranslation();
  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  return (
    <Box>
      <Stepper nonLinear activeStep={activeStep} orientation="vertical">
        {dataSet.steps.map(({ dataSource, status }, index) => (
          <Step key={dataSource} completed={status === 'success'}>
            <StepButton onClick={handleStep(index)}>
              {t('dataSetOverview.stepperLabel', {
                dataSource: appNameToDisplayName[dataSource],
              })}
            </StepButton>
            <StepContent>
              <Typography gutterBottom>
                {status === 'success'
                  ? t('dataSetOverview.successText')
                  : status === 'pending'
                  ? t('dataSetOverview.pendingText')
                  : t('dataSetOverview.errorText')}
              </Typography>
              {status !== 'success' && (
                <Button
                  color="primary"
                  variant="contained"
                  href="#"
                  component={Link}
                  to={createLoginRoute(dataSource)}
                >
                  {t('dataSetOverview.stepAction')}
                </Button>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {dataSet.allCompleted && (
        <Grid container spacing={2} direction="column" alignItems="flex-end">
          <Grid item>
            <Typography>{t('dataSetOverview.completedDescription')}</Typography>
          </Grid>
          <Grid item>
            <Button
              component={Link}
              color="primary"
              variant="contained"
              to="/result"
            >
              {t('dataSetOverview.completedAction')}
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
