import { useEffect, useState } from 'react';

export type AsyncResult<T> =
  | {
      status: 'pending';
    }
  | {
      status: 'resolved';
      value: T;
    };

export const useAsync = <T>(
  asyncFunction: () => Promise<T>,
): AsyncResult<T> => {
  const [result, setResult] = useState<AsyncResult<T>>({
    status: 'pending',
  });

  useEffect(() => {
    asyncFunction()
      .then((value) => {
        setResult({ status: 'resolved', value });
      })
      .catch((error: Error) => {
        setResult(() => {
          throw error;
        });
      });
  }, [asyncFunction]);

  return result;
};
