import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, Theme } from '@material-ui/core/styles';
import React, { PropsWithChildren } from 'react';

export const Page = ({
  children,
  muiTheme,
}: PropsWithChildren<{
  printMode?: boolean;
  muiTheme: Partial<Theme>;
}>) => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};
