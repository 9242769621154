/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://jbi64675v5eqrldqcgiogioqfm.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-3lo6a7yglfcrznkfm2xkt2q2ge",
    "aws_cognito_identity_pool_id": "eu-west-1:ba1126ec-4de2-4378-aee0-a01ca2144a9d",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_0Hl41qhx9",
    "aws_user_pools_web_client_id": "29o2fu6dsue3st55avvajapkrd",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "govdata-exporter160917-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
