import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { ComponentType, PropsWithChildren } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useRouteError } from 'react-router-dom';
import { CardWrapper } from './src/AppWrapper';
import { formatUnknownError } from './src/error-formatting';

export interface ErrorFallbackProps extends FallbackProps {
  Wrapper: ComponentType<PropsWithChildren<Record<string, unknown>>>;
  showBackToLogin?: boolean;
}

function useIsOnNewPage() {
  const location = useLocation();
  return location.pathname !== '/';
}

export function ErrorElement() {
  const { t } = useTranslation();
  const error = useRouteError();
  const navigate = useNavigate();
  const isOnNewPage = useIsOnNewPage();
  return (
    <CardWrapper>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography id="technicalLoginErrorHeading" variant="h1" gutterBottom>
            {t('Technische Fout')}
          </Typography>
          <Typography color="error" gutterBottom>
            {formatUnknownError(error)}
          </Typography>
          <Typography gutterBottom>{t('technicalErrorMessage')}</Typography>
        </Grid>

        <Grid item>
          <Button
            id="retryLoginButton"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              navigate(isOnNewPage ? '/overview' : '/');
            }}
          >
            {t('Opnieuw')}
          </Button>
        </Grid>
      </Grid>
    </CardWrapper>
  );
}
