import { useUrlParameters } from '@nxworkspace/frontend-utils';
import { useEffect, useMemo } from 'react';

const accountToken =
  'SVqHzjnbMnjoX9kxNsOPwIJ5aB7BWDW5KYAD9zCOSu4ram-1FtOnO-AGnumIqCILAZvc1Q';
const namespace = 'chmln';
const previewKey = 'adminPreview';
const methodNames = [
  'setup',
  'identify',
  'alias',
  'track',
  'clear',
  'set',
  'show',
  'on',
  'off',
  'custom',
  'help',
  '_data',
];

function loadScript(url: string) {
  const scriptTag = document.createElement('script');
  scriptTag.src = url;
  scriptTag.async = false;
  scriptTag.defer = false;
  if (!document.head) {
    throw new Error('Expected document.head to be defined');
  }
  document.head.appendChild(scriptTag);
}

declare global {
  interface Window {
    chmln: {
      accountToken?: string;
      location?: string;
      now?: Date;
      identify(
        userid: string,
        data: {
          email: string;
        },
      ): void;
      [key: string]: unknown;
    };
  }
}

const chmln = window[namespace] || {};
window[namespace] = chmln;

if (chmln[previewKey]) {
  chmln[previewKey] = false;
}

if (!chmln['root']) {
  chmln.accountToken = accountToken;
  chmln.location = window.location.href.toString();
  chmln.now = new Date();
  methodNames.forEach((methodName) => {
    const calls: IArguments[] = [];
    chmln[methodName + '_a'] = calls;
    chmln[methodName] = function () {
      // eslint-disable-next-line prefer-rest-params
      calls.push(arguments);
    };
  });
  const scriptUrl =
    'https://fast.trychameleon.com/messo/' + accountToken + '/messo.min.js';
  loadScript(scriptUrl);
}

const rootDomain = 'digitaalwoningdossier.nl';

export function TryChameleonIdentify() {
  const urlParameters = useUrlParameters();
  const userid = useMemo(() => {
    return (
      urlParameters.userid ??
      urlParameters.personaliaid ??
      'unknown-' + Math.round(Math.random() * 10e8)
    );
  }, [urlParameters]);
  useEffect(() => {
    // avoid issues with cross domain iframes
    if (document.domain?.includes(rootDomain)) {
      document.domain = rootDomain;
    }
    chmln.identify(userid, {
      email: 'unknown@mijnhuurdossier.nl',
    });
  }, [userid]);
  return null;
}
