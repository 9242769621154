import { AsyncResult } from './async-hook';
import { useUserInfo } from './common/correlation-info-store';
import { SystemStatusQuery } from './generated/graphql';
import { getAlarmSystems } from './system-status';

const SHOW_LOGIN_PERCENTAGE = 50;

const testUserIds = ['26', '68086'];

function evenDivide(n: number): Set<number> {
  const buckets = new Set<number>();

  for (let i = 0; i < n; i++) {
    buckets.add(Math.floor(i * (100 / n)));
  }

  return buckets;
}

const buckets = evenDivide(SHOW_LOGIN_PERCENTAGE);

export function useShowLogin(
  systemState: AsyncResult<SystemStatusQuery['systemStatus']>,
) {
  const userInfo = useUserInfo();

  if (!userInfo) {
    return true;
  }

  if (testUserIds.includes(userInfo.userId)) {
    return true;
  }

  const personaliaId = +userInfo.personaliaId;
  if (isNaN(personaliaId)) {
    return true;
  }

  const inBucket = buckets.has(personaliaId % 100);

  if (inBucket) {
    return true;
  }
  if (systemState.status === 'pending') {
    return false;
  }
  return getAlarmSystems(systemState).length === 0;
}
