import { Fab } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { appNameToDisplayName, appNameToUrl } from './datasource-info';
import { DataSource } from './generated/graphql';
import { GraphqlResult } from './queries';
import { ShowGraphqlResult, TechnicalErrors } from './show-graphql-result';

const { Mijnoverheid, Belastingdienst, Mijnuwv } = DataSource;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(4),
      borderRadius: 0,
    },
    sendIcon: {
      marginRight: theme.spacing(1),
    },
  }),
);

export const ShowData = ({
  data,
  onSendClick,
  onBackClick,
}: {
  data: GraphqlResult;
  onSendClick: () => void;
  onBackClick: () => void;
}) => {
  const errorState = !!data.errors?.length;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h1">{t('showData.title')}</Typography>
      </Grid>
      <Grid item>
        {errorState && data.errors ? (
          <>
            <Typography paragraph>
              Er zijn fouten opgetreden bij het ophalen van de gegevens.
              Controleer of u uw gegevens kan inzien op de sites van{' '}
              <Link href={appNameToUrl[Mijnoverheid]}>
                {appNameToDisplayName[Mijnoverheid]}
              </Link>
              ,{' '}
              <Link href={appNameToUrl[Mijnuwv]}>
                {appNameToDisplayName[Mijnuwv]}
              </Link>{' '}
              of{' '}
              <Link href={appNameToUrl[Belastingdienst]}>
                {appNameToDisplayName[Belastingdienst]}
              </Link>
              .
            </Typography>
            <Typography>{t('showData.thisDataCouldNotBeRetrieved')}</Typography>
            <TechnicalErrors errors={data.errors} />
          </>
        ) : (
          <Typography>{t('showData.wantToAddData')}</Typography>
        )}
      </Grid>
      {errorState ? (
        <Grid item>
          <Button variant="contained" color="primary" onClick={onBackClick}>
            {t('Terug naar login')}
          </Button>
        </Grid>
      ) : (
        <Fab
          id="submitDataButton"
          variant="extended"
          className={classes.fab}
          color="primary"
          onClick={onSendClick}
        >
          <SendRoundedIcon className={classes.sendIcon} />
          {t('Verder')}
        </Fab>
      )}
      <Grid item>
        <ShowGraphqlResult data={data} />
      </Grid>
    </Grid>
  );
};
