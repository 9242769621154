import {
  DataSource,
  DataSourceDataFragment,
  GetDataSetQuery,
} from '../generated/graphql';

export type DataStatus = 'pending' | 'error' | 'success';

export type DataSourceStep = {
  dataSource: DataSource;
  status: DataStatus;
};

type QueryDataSet = GetDataSetQuery['dataSet'];
export type DataSetKeys = Exclude<keyof QueryDataSet, '__typename'>;

export class DataSet {
  readonly steps: DataSourceStep[];
  readonly activeStep: number;

  constructor(
    private dataSetResponse: GetDataSetQuery['dataSet'],
    private supportedDataSources: DataSetKeys[] = [
      'mijnuwv',
      'mijnoverheid',
      'belastingdienst',
    ],
  ) {
    this.steps = supportedDataSources.flatMap((dataSource) => {
      const graphqlResult = dataSetResponse[dataSource];
      if (typeof graphqlResult === 'string') {
        return [];
      }
      const status = this.getDataSourceStatus(graphqlResult ?? null);
      return {
        dataSource: dataSource as DataSource,
        status,
      };
    });
    this.activeStep = this.steps.findIndex((step) => step.status !== 'success');
  }

  get allCompleted() {
    return this.steps.every((step) => step.status === 'success');
  }

  get combinedData() {
    return Object.assign(
      {},
      ...this.supportedDataSources.map((dataSource) => {
        const dataSetResponseElement = this.dataSetResponse[dataSource];
        if (!dataSetResponseElement) {
          return null;
        }
        return JSON.parse(dataSetResponseElement.data);
      }),
    );
  }

  private getDataSourceStatus(
    dataSourceData: DataSourceDataFragment | null,
  ): DataStatus {
    if (!dataSourceData) {
      return 'pending';
    }
    if ((dataSourceData.errors?.length ?? 0) > 0) {
      return 'error';
    }
    if (!dataSourceData.data) {
      return 'pending';
    }
    return 'success';
  }
}
