import { DataSet } from '../core/DataSet';
import { DataSetStepper } from './DataSetStepper';
import { LoginTypeToggle } from './LoginTypeToggle';

export function DataSetOverview({ dataSet }: { dataSet: DataSet }) {
  return (
    <>
      <LoginTypeToggle />

      <DataSetStepper dataSet={dataSet} />
    </>
  );
}
