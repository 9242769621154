import React from 'react';
import { redirect, useLoaderData, useNavigate } from 'react-router-dom';
import { AppProps } from '../App';
import { DataSet } from '../core/DataSet';
import { getDataSet } from '../queries';
import { createOverviewRoute } from '../routes';
import { ShowData } from '../ShowData';

export const resultPageLoader = (appProps: AppProps) => async () => {
  const dataSet = new DataSet(
    await getDataSet(),
    appProps.supportedDataSources,
  );
  if (!dataSet.allCompleted) {
    return redirect(createOverviewRoute());
  }
  return dataSet;
};

export default function ResultPage({ nextPage }: { nextPage?: string }) {
  const dataSet = useLoaderData() as DataSet;
  const navigate = useNavigate();
  return (
    <ShowData
      data={{ data: dataSet.combinedData }}
      onSendClick={() => {
        navigate(nextPage ?? '/send-data');
      }}
      onBackClick={() => {
        navigate(createOverviewRoute());
      }}
    />
  );
}
