import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useUrlParameters(): {
  token?: string;
  personaliaid?: string;
  userid?: string;
  query?: string;
  [key: string]: string | undefined;
} {
  const location = useLocation();
  return useMemo(() => {
    return Object.fromEntries(
      new URLSearchParams(location.hash.replace(/^#/, '')),
    );
  }, []);
}
