import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AsyncResult } from './async-hook';
import { appNameToDisplayName, appNameToUrl } from './datasource-info';
import { DataSource, SystemStatusQuery } from './generated/graphql';
import { getAlarmSystems } from './system-status';

export interface SystemStatusProps {
  systemStatusState: AsyncResult<SystemStatusQuery['systemStatus']>;
}

function DataSourceErrors({
  datasourcesInAlarm,
}: {
  datasourcesInAlarm: string[];
}) {
  const { t } = useTranslation();
  return (
    <>
      {datasourcesInAlarm.map((alarmSystem, i) => (
        <React.Fragment key={alarmSystem}>
          <Link href={appNameToUrl[alarmSystem as DataSource]}>
            {appNameToDisplayName[alarmSystem as DataSource]}
          </Link>
          {i < datasourcesInAlarm.length - 1
            ? i === datasourcesInAlarm.length - 2
              ? ` ${t('and')} `
              : ', '
            : null}
        </React.Fragment>
      ))}
    </>
  );
}

export function SystemStatus({ systemStatusState }: SystemStatusProps) {
  const { t } = useTranslation();
  if (systemStatusState.status === 'pending') {
    return <Alert severity="info">{t('Systeem status laden...')}</Alert>;
  }
  if (!systemStatusState.value) {
    return (
      <Alert severity="error">
        {t('Fout bij het ophalen van de systeem status')}
      </Alert>
    );
  }
  const alarmSystems = getAlarmSystems(systemStatusState);
  if (!alarmSystems.length) {
    return (
      <Alert severity="success">{t('Alle systemen zijn operationeel!')}</Alert>
    );
  }
  if (alarmSystems.length === 1 && alarmSystems[0] === 'technical') {
    return (
      <Alert severity="error">
        <Typography paragraph>
          {t('systemStatus.genericErrorMessage')}
        </Typography>
        <Typography paragraph>
          <strong>{t('systemStatus.closeAction')}</strong>
        </Typography>
        <Typography>{t('systemStatus.noInfluence')}</Typography>
      </Alert>
    );
  }
  const datasourcesInAlarm = alarmSystems.filter(
    (alarmSystem) => alarmSystem !== 'technical',
  );
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Alert severity="error">
          <Typography paragraph>
            <Trans
              i18nKey="systemStatus.datasourceErrorMessage"
              components={{
                datasourceLinks: (
                  <DataSourceErrors datasourcesInAlarm={datasourcesInAlarm} />
                ),
              }}
              values={{ count: datasourcesInAlarm.length }}
            />
          </Typography>

          <Typography paragraph>
            <strong>{t('systemStatus.closeAction')}</strong>
          </Typography>
          <Typography>{t('systemStatus.noInfluence')}</Typography>
        </Alert>
      </Grid>
    </Grid>
  );
}
