import { createTheme } from '@material-ui/core';
import commonColors from '@material-ui/core/colors/common';

const overrides = {
  MuiCssBaseline: {
    '@global': {
      html: {
        fontSize: '62.5%',
      },
      '@media (max-width: 767px)': {
        html: {
          fontSize: '50%',
        },
      },
    },
  },
};

export const muiTheme = {
  print: createTheme({
    overrides,
    typography: {
      h1: {
        fontSize: '2.25rem',
        lineHeight: '2.5rem',
      },
      h2: {
        fontSize: '1.875rem',
        lineHeight: '2.25rem',
      },
      h3: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
      },
      h4: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
      },
      h5: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
      body1: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
      body2: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
      htmlFontSize: 16,
      fontSize: 16,
    },
  }),
  display: createTheme({
    overrides,
    palette: {
      primary: {
        main: '#55cd8a',
        contrastText: commonColors.white,
      },
      secondary: {
        main: '#3168a3',
        contrastText: commonColors.white,
      },
      error: {
        main: '#a94442',
      },
      text: {
        primary: '#333',
      },
      background: {
        paper: commonColors.white,
        default: '#f8f8f8',
      },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      htmlFontSize: 10,
      fontSize: 13,
      h1: {
        color: '#36495d',
        fontWeight: 600,
        fontSize: '2.1rem',
      },
      h2: {
        color: '#455C72',
        fontWeight: 600,
        fontSize: '1.8rem',
      },
      h3: {
        color: '#36495d',
        fontWeight: 600,
        fontSize: '1.4rem',
      },
      h4: {
        color: '#646C79',
        fontWeight: 600,
        fontSize: '1.4rem',
      },
      h5: {
        color: '#646C79',
        fontWeight: 600,
        fontSize: '1.4rem',
      },
      fontWeightLight: 400,
      fontWeightRegular: 400,
      fontWeightMedium: 600,
      fontWeightBold: 600,
    },
  }),
};
