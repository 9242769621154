import { Button, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { createOverviewRoute } from '../routes';

export function DataSourceNotFound({ dataSource }: { dataSource: string }) {
  const { t } = useTranslation();
  return (
    <Grid container direction="column" spacing={2}>
      <Typography variant="h1" paragraph align="center">
        Unsupported DataSource {dataSource}
      </Typography>
      <Button component={Link} variant="contained" to={createOverviewRoute()}>
        {t('Terug')}
      </Button>
    </Grid>
  );
}
