import { DataSource } from './generated/graphql';

export function createOverviewRoute() {
  return '/overview';
}
export function createLoginRoute(dataSource: DataSource) {
  return `/login/${dataSource}`;
}

export function createRetrieveRoute(dataSource: DataSource) {
  return `/retrieve/${dataSource}`;
}

export function createResultRoute(dataSource: DataSource) {
  return `/result/${dataSource}`;
}
