import { GRAPHQL_AUTH_MODE, GraphQLResult } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';
import { DocumentNode, print } from 'graphql';
import {
  AddDataSourceDataDocument,
  AddDataSourceDataMutation,
  AddDataSourceDataMutationVariables,
  AppLoginDocument,
  AppLoginQuery,
  AppLoginQueryVariables,
  AuthenticateDocument,
  AuthenticateQuery,
  AuthenticateQueryVariables,
  ConfirmAppLoginDocument,
  ConfirmAppLoginQuery,
  ConfirmAppLoginQueryVariables,
  CredentialSessionDocument,
  CredentialSessionQuery,
  CredentialSessionQueryVariables,
  DataSource,
  GetDataSetDocument,
  GetDataSetQuery,
  MijnBelastingdienstDataDocument,
  MijnOverheidDataDocument,
  MijnUwvDataDocument,
  MijnWerkNlDataDocument,
  ResendSmsDocument,
  ResendSmsMutation,
  SendDataDocument,
  SendDataMutation,
  SendDataMutationVariables,
  SessionWithSmsChallengeDocument,
  SessionWithSmsChallengeQuery,
  SessionWithSmsChallengeQueryVariables,
  SystemStatusDocument,
  SystemStatusQuery,
} from './generated/graphql';

export interface GraphqlError {
  path?: (string | number)[] | null;
}

export interface GraphqlResult {
  data?: any;
  errors?: GraphqlError[];

  [key: string]: any;
}

function getData<T>(result: GraphQLResult<T>): T {
  if (!result.data) {
    const error = new Error('expected data to not be null');
    // @ts-ignore
    error.errors = result.errors;
    throw error;
  }
  return result.data;
}

let session: string | null = null;
type NoSession<T> = Omit<T, 'session'>;

export function resetSession() {
  session = null;
}

export function hasSession(): boolean {
  return !!session;
}

export async function getOpenIdToken(
  variables: AuthenticateQueryVariables,
): Promise<AuthenticateQuery> {
  return getData(
    (await API.graphql({
      query: AuthenticateDocument,
      variables,
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })) as GraphQLResult<AuthenticateQuery>,
  );
}

export async function getSystemStatus(): Promise<
  SystemStatusQuery['systemStatus']
> {
  return getData(
    (await API.graphql({
      query: SystemStatusDocument,
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })) as GraphQLResult<SystemStatusQuery>,
  ).systemStatus;
}

export async function queryAppLogin(
  variables: NoSession<AppLoginQueryVariables>,
): Promise<AppLoginQuery['appLogin']> {
  const data = getData(
    (await API.graphql({
      query: AppLoginDocument,
      variables: {
        ...variables,
        session,
      },
    })) as GraphQLResult<AppLoginQuery>,
  );
  const { appLogin } = data;
  if (
    appLogin.__typename === 'AppLoginUrl' ||
    appLogin.__typename === 'Session'
  ) {
    session = appLogin.session;
  }
  return appLogin;
}

export async function queryConfirmAppLogin(
  variables: NoSession<ConfirmAppLoginQueryVariables>,
): Promise<ConfirmAppLoginQuery['confirmAppLogin']> {
  const data = getData(
    (await API.graphql({
      query: ConfirmAppLoginDocument,
      variables: {
        ...variables,
        session,
      },
    })) as GraphQLResult<ConfirmAppLoginQuery>,
  );
  const { confirmAppLogin } = data;
  if (confirmAppLogin.__typename === 'Session') {
    session = confirmAppLogin.session;
  }
  return data.confirmAppLogin;
}

export async function queryCredentialSession(
  variables: NoSession<CredentialSessionQueryVariables>,
): Promise<CredentialSessionQuery['credentialSession']> {
  const data = getData(
    (await API.graphql({
      query: CredentialSessionDocument,
      variables: {
        ...variables,
        session,
      },
    })) as GraphQLResult<CredentialSessionQuery>,
  );
  const { credentialSession } = data;
  if (
    credentialSession.__typename === 'Session' ||
    credentialSession.__typename === 'SmsChallengeSession'
  ) {
    session = credentialSession.session;
  }
  return data.credentialSession;
}

export async function querySessionWithSmsChallenge(
  variables: NoSession<SessionWithSmsChallengeQueryVariables>,
): Promise<SessionWithSmsChallengeQuery['sessionWithSmsChallenge']> {
  const data = getData(
    (await API.graphql({
      query: SessionWithSmsChallengeDocument,
      variables: {
        ...variables,
        session,
      },
    })) as GraphQLResult<SessionWithSmsChallengeQuery>,
  );
  const { sessionWithSmsChallenge } = data;
  if (sessionWithSmsChallenge.__typename === 'Session') {
    session = sessionWithSmsChallenge.session;
  }
  return data.sessionWithSmsChallenge;
}

export async function resendSms() {
  const data = getData(
    (await API.graphql({
      query: ResendSmsDocument,

      variables: {
        session,
      },
    })) as GraphQLResult<ResendSmsMutation>,
  );
  const { resendSms } = data;
  if (resendSms.__typename === 'Session') {
    session = resendSms.session;
  }
  return data.resendSms;
}

export async function retrieveDataSource(
  dataSource: DataSource,
): Promise<GraphqlResult> {
  const query = dataSourceToQuery[dataSource];
  const queryString = print(query);
  const mutationVariables: AddDataSourceDataMutationVariables = {
    dataSource,
    query: queryString,
    variables: JSON.stringify({ session }),
  };
  const mutationResult = (await API.graphql({
    query: AddDataSourceDataDocument,
    variables: mutationVariables,
  })) as GraphQLResult<AddDataSourceDataMutation>;
  const addResult = getData(mutationResult).addDataSourceData;
  return {
    data: JSON.parse(addResult.data),
    errors: addResult.errors ?? undefined,
  };
}

const dataSourceToQuery: Record<DataSource, DocumentNode> = {
  mijnoverheid: MijnOverheidDataDocument,
  mijnuwv: MijnUwvDataDocument,
  belastingdienst: MijnBelastingdienstDataDocument,
  werknl: MijnWerkNlDataDocument,
};

export async function sendData(
  variables: NoSession<SendDataMutationVariables>,
): Promise<SendDataMutation> {
  return getData(
    (await API.graphql({
      query: SendDataDocument,
      variables: {
        ...variables,
        session,
      },
    })) as GraphQLResult<SendDataMutation>,
  );
}

export async function getDataSet(): Promise<GetDataSetQuery['dataSet']> {
  if (!session) {
    return {};
  }
  return getData(
    (await API.graphql({
      query: GetDataSetDocument,
      variables: {
        session,
      },
    })) as GraphQLResult<GetDataSetQuery>,
  ).dataSet;
}
