import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Container, { ContainerTypeMap } from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface WrapperProps {
  children?: React.ReactNode;
  maxWidth?: ContainerTypeMap['props']['maxWidth'];
}

export const NoWrapper = ({ children }: WrapperProps) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const useToggleLanguage = () => {
  const { i18n } = useTranslation();
  return () => {
    const currentIndex = i18n.languages.indexOf(i18n.language);
    const newIndex = (currentIndex + 1) % i18n.languages.length;
    i18n.changeLanguage(i18n.languages[newIndex]);
  };
};

export const CardWrapper = ({ children, maxWidth = 'xs' }: WrapperProps) => {
  const toggleLanguage = useToggleLanguage();
  const { t } = useTranslation();
  return (
    <Container component="main" maxWidth={maxWidth} disableGutters>
      <Card>
        <Grid component={CardContent} container direction="column" spacing={2}>
          <Grid item container justifyContent="flex-end">
            <Link
              href="#"
              align="right"
              onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                toggleLanguage();
              }}
            >
              {t('toggleLanguage')}
            </Link>
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
      </Card>
    </Container>
  );
};
