import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ArrowForward } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAsync } from './async-hook';
import { appNameToDisplayName } from './datasource-info';
import { getSystemStatus } from './queries';
import { createOverviewRoute } from './routes';
import { SystemStatus } from './SystemStatus';
import { useShowLogin } from './useShowLogin';

export const Introduction = () => {
  const { t } = useTranslation();
  const systemStatusState = useAsync(getSystemStatus);

  const showLogin = useShowLogin(systemStatusState);

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Typography variant="h1">{t('introduction.title')}</Typography>
        <List>
          {(
            t('introduction.processSteps', {
              returnObjects: true,
            }) as string[]
          ).map((step, i) => (
            <ListItem key={i}>
              <ListItemIcon>
                <ArrowForward />
              </ListItemIcon>
              <ListItemText>{step}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item>
        <SystemStatus systemStatusState={systemStatusState} />
      </Grid>

      {showLogin && (
        <Grid item>
          <Button
            id="toLoginButton"
            type="submit"
            color="primary"
            variant="contained"
            component={Link}
            fullWidth
            to={createOverviewRoute()}
          >
            {t('Naar inloggen')}
          </Button>
        </Grid>
      )}

      <Grid item>
        <Typography variant="h2">
          {t('introduction.dataTitle', {
            dataSources: `${appNameToDisplayName.mijnuwv}, ${
              appNameToDisplayName.mijnoverheid
            } ${t('and')} ${appNameToDisplayName.belastingdienst}`,
          })}
        </Typography>
        <ul>
          {(
            t('introduction.dataNames', {
              returnObjects: true,
            }) as string[]
          ).map((dataName, i) => (
            <Typography variant="body1" component="li" key={i}>
              {dataName}
            </Typography>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
};
