import { DataSource } from './generated/graphql';

export const appNameToDisplayName: Record<DataSource, string> = {
  mijnoverheid: 'www.mijnoverheid.nl',
  mijnuwv: 'www.uwv.nl',
  werknl: 'werk.nl',
  belastingdienst: 'www.belastingdienst.nl',
};

export const appNameToUrl: Record<DataSource, string> = {
  mijnoverheid: 'https://mijn.overheid.nl/',
  mijnuwv: 'https://www.uwv.nl/particulieren/mijnuwv/index.aspx',
  werknl: 'https://www.werk.nl/werkzoekenden/werkmap',
  belastingdienst: 'https://mijn.belastingdienst.nl/mbd-pmb/pmb.html',
};

export const graphqlFieldToDataSource: Record<string, DataSource> = {
  mijnUwvData: DataSource.Mijnuwv,
  mijnOverheidData: DataSource.Mijnoverheid,
  mijnBelastingdienstData: DataSource.Belastingdienst,
  werkNlData: DataSource.Werknl,
};

export const graphqlTypeToDataSource: Record<string, DataSource> = {
  MijnUwvData: DataSource.Mijnuwv,
  MijnOverheidData: DataSource.Mijnoverheid,
  MijnBelastingdienstData: DataSource.Belastingdienst,
  WerkNlData: DataSource.Werknl,
};

export const stringToDataSource: Record<string, DataSource> = {
  mijnuwv: DataSource.Mijnuwv,
  mijnoverheid: DataSource.Mijnoverheid,
  belastingdienst: DataSource.Belastingdienst,
  werknl: DataSource.Werknl,
};

export function graphqlFieldToDisplayName(fieldName: string) {
  return appNameToDisplayName[graphqlFieldToDataSource[fieldName]];
}
