import { Button, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography variant="h1">{t('notFound.title')}</Typography>
      </Grid>
      <Grid item>
        <Button component={Link} variant="contained" color="primary" to="/">
          {t('notFound.backButton')}
        </Button>
      </Grid>
    </Grid>
  );
}
