import { BUILD_DATE, BUILD_NUMBER, GIT_HASH } from '@nxworkspace/version';

declare global {
  interface Window {
    version: {
      buildDate: string;
      buildNumber: string;
      gitHash: string;
    };
  }
}

export function setVersion() {
  window.version = {
    buildDate: BUILD_DATE,
    buildNumber: BUILD_NUMBER,
    gitHash: GIT_HASH,
  };
}
